<template>
  <div class="container">
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>基本信息</div>
    </div>
    <div class="table-info">
      <div class="info-row">
        <div class="info-list">
          <div class="info-head">活动名称：</div>
          <div class="info-details">{{ info.act_name }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">是否显示入口：</div>
          <div class="info-details">{{ info.is_show == 1 ? "是" : "否" }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">活动时间：</div>
          <div class="info-details">
            {{ info.act_time[0] + "至" + info.act_time[1] }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">限购：</div>
          <div class="info-details">
            {{
              info.limit_buy == 1
                ? "每人每spu限购" + info.limit_buy_num + "件"
                : "不限购"
            }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">标签显示：</div>
          <div class="info-details">{{ info.laber }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">是否展示优惠券入口：</div>
          <div class="info-details">
            {{ info.is_show_coupon == 1 ? "是" : "否" }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">活动开关：</div>
          <div class="info-details">
            {{ info.act_status == 1 ? "开启" : "关闭" }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">活动分享：</div>
          <div class="info-details">
            {{ info.is_share == 1 ? "可分享" : "不可分享" }}
          </div>
        </div>
      </div>
      <div class="info-row">
        <div class="pictur-list">
          <div class="pictur-head">活动入口图：</div>
          <div class="pictur-details">
            <div class="pictur-box">
              <el-image
                fit="fill"
                :src="info.enter_img_str"
                style="width:100px;height:100px"
              />
            </div>
          </div>
        </div>
        <div class="pictur-list">
          <div class="pictur-head">活动banner页：</div>
          <div class="pictur-details">
            <div class="pictur-box">
              <el-image
                fit="fill"
                :src="info.banner_img_str"
                style="width:100px;height:100px"
              />
            </div>
          </div>
        </div>
        <div class="pictur-list">
          <div class="pictur-head">分享海报图：</div>
          <div class="pictur-details">
            <div class="pictur-box">
              <el-image
                fit="fill"
                :src="info.share_poster_str"
                style="width:100px;height:100px"
              />
            </div>
          </div>
        </div>
        <div class="pictur-list">
          <div class="pictur-head">分享卡片图：</div>
          <div class="pictur-details">
            <div class="pictur-box">
              <el-image
                fit="fill"
                :src="info.share_img_str"
                style="width:100px;height:100px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>商品列表</div>
    </div>
    <div style="border: solid 1px #c9cbd6">
      <el-table
        ref="multipleTable"
        :data="info.goods_list"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        :header-cell-style="{ background: '#F5F5F5' }"
      >
        <!-- <el-table-column label="排序"
                         prop="sort"></el-table-column> -->
        <el-table-column label="商品" width="320">
          <template slot-scope="scope">
            <div class="flex align-center" style="width: 300px">
              <img
                v-if="scope.row.picture == ''"
                :src="defaultImg"
                style="width: 76px; height: 76px"
              />
              <el-image
                v-else
                :src="scope.row.picture_str"
                style="width: 76px; height: 76px"
              />
              <div style="margin-left:10px;">{{ scope.row.goods_name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sku_name" label="规格" />
        <el-table-column prop="price" label="价格" />
        <el-table-column prop="member_price" label="会员价">
          <template slot-scope="scope">
            {{
              scope.row.member_price === "0.00" ? "" : scope.row.member_price
            }}
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="库存" />
        <el-table-column label="活动价" prop="promote_price" />
        <el-table-column label="活动限售量" prop="limit_sale" />
        <el-table-column label="置顶图片">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.poster_img_str"
              :src="scope.row.poster_img_str"
              style="width: 76px; height: 76px"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="lineH-box" style="margin-top: 20px">
      <div class="lineH"></div>
      <div>效果数据</div>
    </div>
    <div class="excle" v-if="!statusCoupon">
      <div class="excle_itemBox">
        <div class="pay_info_box">
          <div
            class="p_info_item"
            :style="ColorItem == 1 ? `background: rgb(78, 135, 247, 0.2)` : ''"
            @click="clickColorItem(1)"
          >
            <div class="p_info_title">
              {{ "活动总成交额" }}
              <el-tooltip
                class="item"
                effect="dark"
                popper-class="nowarp"
                content="在活动期间包含活动商品的付款总金额"
                placement="top-end"
              >
                <img :src="what" alt="" style="width: 12px; height: 12px" />
              </el-tooltip>
            </div>
            <div class="p_info_numb">
              {{ couponstatisticaldata.total_money }}
            </div>
          </div>
          <div
            class="p_info_item"
            :style="ColorItem == 2 ? `background: rgb(78, 135, 247, 0.2)` : ''"
            @click="clickColorItem(2)"
          >
            <div class="p_info_title">
              {{ "优惠总金额" }}
              <el-tooltip
                class="item"
                effect="dark"
                popper-class="nowarp"
                content="已付款订单中活动商品优惠的总金额"
                placement="top-end"
              >
                <img :src="what" alt="" style="width: 12px; height: 12px" />
              </el-tooltip>
            </div>
            <div class="p_info_numb">
              {{ couponstatisticaldata.activity_price }}
            </div>
          </div>
          <div
            class="p_info_item"
            @click="clickColorItem(3)"
            :style="ColorItem == 3 ? `background: rgb(78, 135, 247, 0.2)` : ''"
          >
            <div class="p_info_title">
              {{ "活动新客户数" }}
              <el-tooltip
                class="item"
                effect="dark"
                popper-class="nowarp"
                content="首次活动商品下单支付且首次在平台下单支付的用户"
                placement="top"
              >
                <img :src="what" alt="" style="width: 12px; height: 12px" />
              </el-tooltip>
            </div>
            <div class="p_info_numb">
              {{ couponstatisticaldata.new_user_list }}
            </div>
          </div>
          <div
            class="p_info_item"
            @click="clickColorItem(4)"
            :style="ColorItem == 4 ? `background: rgb(78, 135, 247, 0.2)` : ''"
          >
            <div class="p_info_title">
              {{ "活动老客户数" }}
              <el-tooltip
                class="item"
                effect="dark"
                popper-class="nowarp"
                content="首次活动商品下单且在平台下单支付过的用户"
                placement="top-end"
              >
                <img :src="what" alt="" style="width: 12px; height: 12px" />
              </el-tooltip>
            </div>
            <div class="p_info_numb">
              {{ couponstatisticaldata.old_user_list }}
            </div>
          </div>
          <div
            class="p_info_item"
            @click="clickColorItem(5)"
            :style="ColorItem == 5 ? `background: rgb(78, 135, 247, 0.2)` : ''"
          >
            <div class="p_info_title">
              {{ "活动新客户订单" }}
              <el-tooltip
                class="item"
                effect="dark"
                popper-class="nowarp"
                content="活动新客户订单总金额/订单数量"
                placement="top-end"
              >
                <img :src="what" alt="" style="width: 12px; height: 12px" />
              </el-tooltip>
            </div>
            <div class="p_info_numb">
              ￥{{ couponstatisticaldata.new_user_order_mony }}/{{
                couponstatisticaldata.new_user_order_count
              }}
            </div>
          </div>
          <div
            class="p_info_item"
            @click="clickColorItem(6)"
            :style="ColorItem == 6 ? `background: rgb(78, 135, 247, 0.2)` : ''"
          >
            <div class="p_info_title">
              {{ "活动老客户订单" }}
              <el-tooltip
                class="item"
                effect="dark"
                popper-class="nowarp"
                content="活动老客户订单总金额/订单数量"
                placement="top-end"
              >
                <img :src="what" alt="" style="width: 12px; height: 12px" />
              </el-tooltip>
            </div>
            <div class="p_info_numb">
              ￥{{ couponstatisticaldata.old_user_order_mony }}/{{
                couponstatisticaldata.old_user_order_count
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="timeTotal">
        <div>统计时间:</div>
        <el-date-picker
          v-model="timeTotal"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="changeTime"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div class="echarts_box">
        <div ref="chart" style="width: 90%; height: 376px"></div>
        <div style="width: 90%; text-align: center">
          <div>
            <span
              style="  
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  background: #4974f5;
                  display: inline-block;
                  margin-right: 5px;
                "
            ></span>
            <span>{{ title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="statusCoupon">活动未生效，暂无数据</div>
  </div>
</template>
<script>
import what from "../../../assets/what.svg";
import dayjs from "dayjs";
import operation from "@/api/operation";
export default {
  name: "addOrCheck",
  data() {
    return {
      info: {
        act_name: "",
        is_show: 1,
        act_time: [],
        limit_buy: 0,
        limit_buy_num: "",
        banner_img: "",
        banner_img_str: "",
        enter_img: "",
        enter_img_str: "",
        share_img: "",
        share_img_str: "",
        share_poster: "",
        share_poster_str: "",
        laber: "",
        is_show_coupon: 0,
        goods_list: [],
        is_share: 1,
      },
      statusCoupon: false,
      couponstatisticaldata: {}, //统计数据
      ColorItem: 1,
      timeTotal: [],
      what: what,
      title: "已发放数量",
    };
  },
  created() {
    this.act_id = true;
    this.getInfo();
  },
  computed: {
    pickerOptions() {
      let that = this;
      return {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate(time) {
          let endTime = that.info.end_time;
          let show_start_time = that.info.start_time;
          let timeValue = that.dayjs(time).unix();
          let now = that.dayjs().unix();
          if (endTime < now) {
            return timeValue < show_start_time || endTime < timeValue;
          }
          if (now < endTime && show_start_time < now) {
            return timeValue < show_start_time || now < timeValue;
          }
          if (now < show_start_time) {
            return true;
          }
        },
      };
    },
  },
  methods: {
    //获取详情
    getInfo() {
      let act_id = this.$route.query.act_id;
      operation.getActivityInfo({ act_id }).then((res) => {
        let info = res.data.info;
        this.info = info;
        this.info.act_time = [
          dayjs.unix(info.start_time).format("YYYY-MM-DD HH:mm"),
          dayjs.unix(info.end_time).format("YYYY-MM-DD HH:mm"),
        ];
        if (info.limit_buy > 0) {
          this.info.limit_buy_num = info.limit_buy;
          this.info.limit_buy = 1;
        }
        this.info.act_id = this.$route.query.act_id;
        this.getTimeTotal();
        if (this.info.start_time < this.dayjs().unix()) {
          this.statusCoupon = false;
        } else {
          this.statusCoupon = true;
        }
      });
    },
    getTimeTotal() {
      let that = this;
      let endTime = that.info.end_time;
      let show_start_time = that.info.start_time;
      let now = that.dayjs().unix();
      // console.log("now", now);
      if (endTime < now) {
        this.timeTotal = [
          this.dayjs.unix(show_start_time),
          this.dayjs.unix(endTime),
        ];
      }
      if (now < endTime && show_start_time < now) {
        this.timeTotal = [
          this.dayjs.unix(show_start_time),
          this.dayjs.unix(now),
        ];
      }
      if (now < show_start_time) {
        this.timeTotal = [];
      }
      this.getCouponData();
    },
    getCouponData() {
      let data = {
        act_id: this.$route.query.act_id,
      };
      operation.getActivityStatisticalData(data).then((res) => {
        this.couponstatisticaldata = res.data;
        this.getlineData();
      });
    },
    getlineData() {
      if (this.timeTotal) {
        let create_time_start = this.dayjs(this.timeTotal[0]).format(
          "YYYY-MM-DD"
        );
        let create_time_end = this.dayjs(this.timeTotal[1]).format(
          "YYYY-MM-DD"
        );
        let params = {
          act_id: this.$route.query.act_id,
          create_time_start: create_time_start,
          create_time_end: create_time_end,
          type: this.ColorItem,
        };
        console.log("params", params);
        operation.getActivityLineStatisticalData(params).then((res) => {
          let time = res.data.map((el) => {
            return {
              value: this.dayjs(el.time).format("YYYY-MM-DD"),
            };
          });
          let str = res.data.map((e) => {
            let weeks = new Array("日", "一", "二", "三", "四", "五", "六");
            let money = [3, 4, 7, 8];
            return {
              value: e.str,
              str: money.indexOf(this.ColorItem) != -1 ? `￥${e.str}` : false,
              str1: e.str1,
              length: res.data.length,
              type: this.ColorItem == 7 || this.ColorItem == 8 ? true : false,
              day: weeks[this.dayjs(e.time).day()],
            };
          });
          this.getEchartData(time, str);
        });
      }
    },
    clickColorItem(val) {
      this.ColorItem = val;
      let titleBox = [
        "活动总成交额",
        "优惠总金额",
        "活动新客户数",
        "活动老客户数",
        "活动新客户订单",
        "活动老客户订单",
      ];
      this.title = titleBox[val - 1];
      this.getlineData();
    },
    changeTime() {
      this.getlineData();
    },
    getEchartData(time, str) {
      this.$echarts.dispose(this.$refs.chart); //删除dom结点方便从新渲染
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            backgroundColor: "rgba(13, 13, 13,0.7)",
            textStyle: {
              color: "rgba(255,255, 255, 1)",
            },
            formatter: function(params) {
              console.log(params); //params是echarts的属性
              let res = params; //变量一个res
              //return回调一个模板字符串，自定义提示框的形状
              return `<div>${res.name}(${res.data.day})</div>
              ${
                res.data.length == 1 && res.data.type == true
                  ? `<div>
                      <div><span style="
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background: #4974f5;
                                display: inline-block;
                                margin-right: 2px;">
                          </span>
                          <span style="margin-right:30px;">总金额</span>
                          <span>${
                            res.data.str ? res.data.str : res.value
                          }</span>
                      </div>
                      <div>
                      <span style="
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: #4974f5;
                            display: inline-block;
                            margin-right: 2px;">
                      </span>
                      <span style="margin-right:30px;">订单数</span>
                      <span>${res.data.str1 ? `${res.data.str1}` : "0"}</span>
                      </div>
                    </div>
                  `
                  : `<div><span style="
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #4974f5;
                    display: inline-block;
                    margin-right: 2px;">
                    </span>
                    <span style="margin-right:30px;">总计</span>
                    <span>${res.data.str ? res.data.str : res.value}${
                      res.data.str1 ? `/${res.data.str1}` : ""
                    }</span>
                    </div>`
              }
              `;
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false, //对齐坐标刻度点
            min: -1,
            max: function(value) {
              return value.max + 1;
            }, //最大x刻度的个数
            data: time,
            axisLabel: {
              formatter: function(value, index) {
                return value;
              },
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: str,
              type: "line",
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function() {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function() {
          myChart.resize();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.table-info {
  width: 80%;
  padding: 10px 11px;
  .info-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .info-head {
      text-align: right;
    }
    .info-list {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}
.pictur-list {
  width: 50%;
  margin-bottom: 10px;
  display: flex;
  .pictur-head {
    width: 100px;
    text-align: right;
  }
}
.pictur-details {
  display: flex;
}
.pictur-box {
  width: 100%;
  height: 100%;
  margin-right: 5px;
}
.goods-info {
  padding: 10px 11px;
}
.empty-box {
  margin: 10px 20px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e4;
}
.descriptionPictur {
  padding: 10px 20px;
  width: 100%;
  display: flex;
}
.deviler {
  display: flex;
  padding: 10px 13px;
}
.excle {
  .excle_itemBox {
    .Item {
      .title {
      }
      .number {
      }
    }
  }
  .timeTotal {
    display: flex;
    align-items: center;
  }
  .echarts_box {
    width: 100%;
  }
}
.pay_info_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .p_info_item {
    width: 23%;
    height: 70px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px #eeeeee;
    margin-bottom: 15px;
    border-radius: 8px;
    margin-right: 1%;
    .p_info_title {
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      color: #4d4d4d;
      margin-bottom: 4px;
    }
    .p_info_numb {
      height: 24px;
      font-size: 20px;
      line-height: 24px;
      color: #4d4d4d;
    }
  }
}
</style>
